import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    articles: [],
    articleDetail: null,
    error: null,
}

export const articleSlice = createSlice ({
    name: 'article',
    initialState,
    reducers: {
        getArticlesStart: state => {
            state.isLoading = true
        },
        getArticleSuccess: (state, action) => {
            state.isLoading = false
            state.articles = action.payload
        },
        getArticleFailure: (state, action) => {
            state.error = action.payload
            state.isLoading = false
        },

        getArticleDetailStart: state => {
            state.isLoading = true
        },
        getArticleDetailSuccess: (state, action) => {
            state.isLoading = false
            state.articleDetail = action.payload
        },
        getArticleDetailFailure: (state, action) => {
            state.isLoading = false
        },
        postArticleStart: state => {
            state.isLoading = false
        },
        postArticleSuccess: state => {
            state.isLoading = false
        },
        postArticleFailure: state => {
            state.isLoading = false
            state.error = 'Error'
        },

    },
})

export const {getArticleFailure, getArticleSuccess, getArticlesStart,
     getArticleDetailFailure, getArticleDetailStart,getArticleDetailSuccess,
     postArticleFailure, postArticleStart, postArticleSuccess } = articleSlice.actions
export default articleSlice.reducer


// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     isLoading: false,
//     articles: [],
//     error: null,
// }

// export const articleSlice = createSlice({
//     name: 'article',
//     initialState,
//     reducers: {
//         getArticlesStart: state => {
//             state.isLoading = true;
//         },
//         getArticlesSuccess: (state, action) => {
//             state.isLoading = false;
//             state.articles = action.payload;
//         },
//         getArticlesFailure: (state, action) => {
//             state.isLoading = false;
//             state.error = action.payload;
//         }
//     },
// })

// export const { getArticlesSuccess, getArticlesStart, getArticlesFailure } = articleSlice.actions;
// export default articleSlice.reducer;
